<template>
    <div class="all_head">
       <div class="is_site">
        <img :src="isLogo" alt="" style="width: 122px;height:32px;">
        <div class="is_title">
            <span @click="goType(1)">首页</span>
            <span @click="goType(2)">所有版本</span>
            <span @click="goType(3)">常见问题</span>
            <span @click="goType(4)">用户评价</span>
            <span @click="goType(5)">联系客服</span>
        </div>
       </div>
    </div>
</template>
<script>
export default {
    name:'AllHead',
    props:['info'],
    data(){
        return {
            isLogoObj:{
                ps:require('../assets/img/img_logo_ps.png'),
                pr:require('../assets/img/img_logo_pr.png'),
                adobe:require('../assets/img/img_logo.png'),
                dc:require('../assets/img/img_logo_PDF.png'),
            },
            isLogo:''
        }
    }, 
    watch:{
      $route:{
        handler: function () {
            this.isLogo = this.isLogoObj[this.$route.name]
        },
        deep:true,
        immediate:true
      }
    },
    methods: {
        goType(num) {
            if ( num == 5 ) {
                window.open(this.info.complainLink)
            } else {
                this.$emit('scrollFn',num)
            }
        }
    },
}
</script>
<style scoped lang="scss">
.all_head {
    width: 100%;
    height: 80px;
    border-bottom: 1px solid #ccc;
    position: fixed;
    background-color: #fff;
    z-index: 999;
    .is_site {
        width:1478px;
        height: 80px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .is_title {
        width: 450px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            display: block;
            height: 24px;
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            line-height: 21px;
            cursor: pointer;
        }
        span:last-child {
            width: 120px;
            height: 40px;
            background: #0F86FB;
            border-radius: 57px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 21px;
        } 
    }
}
</style>