import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'adobe',
    // redirect: '/firstpage',
    meta:{
      name:'adobe',
      title: '首页',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },// 首页
  {
    path: '/adobe',
    name: 'adobe',
    meta:{
      name:'adobe',
      title: '首页',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/ps',
    name: 'ps',
    meta:{
      name:'ps',
      title: 'ps',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  ,
  {
    path: '/dc',
    name: 'dc',
    meta:{
      name:'dc',
      title: 'dc',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/pr',
    name: 'pr',
    meta:{
      name:'pr',
      title: 'pr',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    meta:{
      name:'detail',
      title: '详情页',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/detail.vue')
  },
  {
    path: '/download',
    name: 'download',
    meta:{
      name:'download',
      title: '详情页',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/download.vue')
  },
]


const router = new VueRouter({
  mode: 'history',
  // base: '/web',
  routes
})

router.afterEach((to, from) => {
  if (to.meta.name) {
    var favIco = document.getElementById('favicon')
    favIco.href = '/static/' + to.meta.name + '.ico'
  }
})


export default router
